<template>
    <div>
        <v-container class="py-10">
            <h2 class="px-5 py-5">{{ $t('Results Details L.M.S Report') }}</h2>
            <v-row>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
                        v-model="filters.from">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date"
                        v-model="filters.to">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <AutoCompleteField end-point="/lms/quiz" :label="$t('Quiz Name')" v-model="filters.quiz_id"/>
                </v-col>
                <v-col md="3">
                    <AutoCompleteField end-point="/user/auto-complete" :label="$t('Quiz Creator')"
                        v-model="filters.user_id" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('student name')" type="regRecord" v-model="filters.registration_record_id"
                        endPoint="/student/auto-complete" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('class')" v-model="filters.grade_id" endPoint="/grade" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('educitional class')" v-model="filters.edu_class_id"
                        endPoint="/edu-class" />
                </v-col>
                <!-- <v-col md="2">
                    <v-autocomplete :dark="$store.state.isDarkMode" color="#757575" :label="$t('Group By')"
                        :items="group_by" item-text="name" item-value="value" v-model="filters.group_by"></v-autocomplete>
                </v-col> -->
                <v-col md="3">
                    <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Comments')" class="mx-4 mb-4"
                        v-model="filters.has_comment" hide-details></v-checkbox>
                </v-col>
                
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="green" dark block @click="fetchData(1)">{{ $t('search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <ExportExcel end-point="/lms/student-answer/export" :params="filters" />
                </v-col>

                <v-col md="12">
                    <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
                        @re-fetch-paginated-data="fetchData($event)" :enableDelete="false" :displaySettings="false">
                    </app-base-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import deleteItem from "../../../components/core/delete.vue";
import ExportExcel from "../../../components/core/ExportExcel.vue";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import i18n from "@/i18n";

import axios from "axios";
export default {
    components: { deleteItem, ExportExcel, AutoCompleteField },
    data() {
        return {
            tableOptions: {
                tableData: [],
                tableHeaders: ["date", "student name", "educitional class", "Quiz Name",
                               "Question", "Question Result", "Comments"],
                            //    , "Student Result"
                accessTableData: [["date"],["student"],["edu_class"],["quiz"], ["question"], ["mark"], ["comment"]],
                loading: false,
                tableMeta: {
                    total: 10,
                    page: 1,
                },
            },
            group_by: [
                {
                    name: i18n.t('student name'),
                    value: "student_name",
                },
                {
                    name: i18n.t('class'),
                    value: "grade",
                },
                {
                    name: i18n.t('Quiz Name'),
                    value: "quiz_name",
                },
            ],
            filters: {
                from: null,
                to: null,
                quiz_id: null,
                user_id: null,
                group_by: "grade",
                edu_class_id: null,
                grade_id: null,
                registration_record_id: null,
                has_comment: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            units: "subjects/getUnits",
        }),
    },
    methods: {
        ...mapActions({
            fetchUnits: "subjects/fetchUnits",
        }),
        async fetchData(page) {
            this.tableOptions.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                size: 10,
                page: page,
                ...filterTemp,
            };
            const response = await axios.get(`/lms/student-answer/report`, { params });
            if (response.status == 200) {
                console.log(response.data.data);
                this.tableOptions.tableData = response.data.data,
                this.tableOptions.tableMeta.total = response.data.meta.total;
                this.tableOptions.tableMeta.page = response.data.meta.current_page;
            }
            this.tableOptions.loading = false;
        },
        clearFilter() {
            this.filters.user_id = undefined;
            this.filters.quiz_id = undefined;
            this.filters.from = undefined;
            this.filters.to = undefined;
            this.filters.edu_class_id = undefined;
            this.filters.grade_id = undefined;
            this.filters.has_comment = undefined;
            this.filters.registration_record_id = undefined;
            this.fetchData(1);
        },
    },
    created() {
        this.fetchData(1);
    },
};
</script>
